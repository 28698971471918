
amplify-select-mfa-type {
    min-height: 100%;
    min-height: 100vh;
    display: inline-block;
    vertical-align: middle;
}


amplify-sign-out {
    position: fixed;
    bottom: 10vmin;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 80%;
    max-width: 240px;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container .logo {
    display: flex;
    justify-content: center;
    padding-top: 10vmin;
    max-width: 320px;
    width: 80%;
}

.container amplify-sign-in {
    display: flex;
    justify-content: center;
    vertical-align: top;
    height: 80vh;
}

.container amplify-sign-up {
    display: flex;
    justify-content: center;
    vertical-align: top;
    height: 80vh;
}

.container amplify-forgot-password {
    display: flex;
    justify-content: center;
    vertical-align: top;
    height: 80vh;
}

.container amplify-confirm-sign-in {
    display: flex;
    justify-content: center;
    vertical-align: top;
    height: 80vh;
}

.container amplify-confirm-sign-up {
    display: flex;
    justify-content: center;
    vertical-align: top;
    height: 80vh;
}
